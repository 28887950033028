.page-header {
	text-align: center;

	&__breadcrumbs,
	&__title {
		color: #ffffff;
	}

	&__breadcrumbs {
		text-transform: uppercase;
		margin-bottom: 1.5rem;

		.separator:after {
			content: '';
			position: relative;
			display: inline-block;
			vertical-align: middle;
			width: 2rem;
			height: 1px;
			margin: 0 0.5rem;
			background: $light;
		}
	}

	&__title {
		font-size: 2rem;

		@include respond-above(sm) {
			font-size: 3rem;
		}
	}

	&__backdrop {
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.8) 100%);
	}
}
