$b2c-authentication-wrapper-background-field-form: rgba($color: map-get($map: $mat-accent, $key: 200), $alpha: 0.5);
// map-get(
// 	$map: $mat-accent,
// 	$key: 200
// );

b2c-authentication-wrapper{
    .mat-form-field-wrapper{
        border: 1px solid $secondary;
        background-color: $b2c-authentication-wrapper-background-field-form;
        padding: 0 0.5rem;
        margin: 0.5rem 0;  

        }
    
    .mat-form-field-underline{
        display: none;
    }
    .mat-toolbar{
        justify-content: center;
    }
    .mat-raised-button{
        background-color: $accent;
        color: #000;
        border-radius: 56px;
        width: 100px;
        font-weight: 400;
    }   

}