b2c-checkout{
    .page-header {
        background-image: linear-gradient(181deg, rgba(253,244,227,1) 35%, rgba(255,255,255,1) 100%) !important;
        &__breadcrumbs,
        &__title{
            color: black;
        }
        &__breadcrumbs .separator:after{
            background: #000;
        }
    }
    .buttonLink{
        &__button{
            font-size: 1rem;
            font-weight: 400;
            line-height: 48px;
            padding: 0 32px;
            border-radius: 56px;
            min-width: 150px;
            box-shadow: none;
            justify-content: center;
            &.mat-raised-button.mat-primary {
                background-color: $accent;
                color: #000;
            }
            &.mat-raised-button.mat-button-disabled.mat-button-disabled {
                background-color: rgba(0, 0, 0, 0.12);
                color: rgba(0, 0, 0, 0.26);
            }
        }
        &__containerText{
            color: $accent;
            font-weight: 500;
        }
    }
    .card-information{
        &__content{
            color: $secondary;
        }
    }
    .total, .partial{
        background-color: rgba($color: map-get($map: $mat-accent, $key: 200), $alpha: 0.5);
        color: $secondary;
        padding: 0.5rem 1rem;
        font-weight: 500;
    }
    .total{
        margin-bottom: 0;
    }
    .partial{
        border-bottom: none;
    }
}
