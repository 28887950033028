/*
*  Theme name: Customer theme
*  This file contains variables different from var-base.scss
*/

@import '../var-base';
/*
*  override START
*/
$primary: #607f62;
$accent: #f3bf4f;
$secondary: #ac855f;

$alert-button-content-color: $light;
$warn-button-content-color: $light;

// Custom Font
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500&display=swap');
$font-family-name: 'Work Sans', sans-serif;
// Header
$header-height: 120px;
/*
*  extend START
*/

/*
*  extend END
*/
@import '../../abstract/abstract';
@import '@saep-ict/angular-core/src/styles/var-base-process';
